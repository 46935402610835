import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  modalButton: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    padding: 20,
    textDecoration: 'none',
  },
  whiteBgButton: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    border: `2px solid ${COLORS.black}`,
    marginBottom: 10,
  },
  createShareLink: {
    cursor: 'pointer',
    '&:disabled': {
      border: `1px solid #999999`,
      backgroundColor: `#cccccc`,
      color: `#666666`,
    },
  },
  inputContainer: {
    width: '100%',
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  convertedLink: {
    position: 'relative',
    ['& > input']: {
      color: theme.palette.common.black,
      letterSpacing: 1.2,
      textAlign: 'left',
      background: 'white',
      border: `1px solid ${COLORS.lightGrey}`,
    },
    ['& > button']: {
      margin: 0,
      [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100px',
        margin: 0,
      },
    },
  },
  convertAnotherLinkBtn: {
    fontSize: '1rem' /*equals 16px*/,
    fontFamily: FONTS.Graphik.GraphikLight,
    background: 'transparent',
    border: 'none',
    display: 'block',
    margin: '0px auto',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  alertText: {
    position: 'absolute',
    left: 0,
    bottom: -10,
    margin: 0,
    fontSize: '0.875rem' /*equals 14px*/,
  },
  currentMessageStyle: {
    color: COLORS.infoBlue,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: -10,
    marginTop: -10,
  },
  errorMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
}))
