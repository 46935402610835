import React, { useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Box, Typography } from '@material-ui/core'
import { styles } from './styles'
import axios from '../../axios'
import { baseURL, ENDPOINTS } from '../../constants'
import CHRButton from '../button'
import CHRInput from '../input'
import CircularProgress from '@material-ui/core/CircularProgress'

interface ShortLink {
  trackingLink: string
  value: string
  commission: string
  isLoading: boolean
}

interface CreateProductShareLinkProps {
  t: TFunction
  networkId: number
  productId: string
  productUrl: string
  productName: string
  productImage: string
  shortenedLink: ShortLink
  setShortenedLink: (state: ShortLink) => void
  isLoggedIn: boolean
  handleOpenModal: Function
}

const CreateProductShareLink = ({
  t,
  networkId,
  productId,
  productUrl,
  productName,
  productImage,
  shortenedLink,
  setShortenedLink,
  isLoggedIn,
  handleOpenModal,
}: CreateProductShareLinkProps) => {
  const [copyMessage, setCopyMessage] = useState('')
  const [error, setError] = useState('')
  const textAreaRef = useRef(null)
  const classes = styles()

  const handleCreateLink = async () => {
    try {
      if (isLoggedIn) {
        setShortenedLink({
          trackingLink: '',
          value: '',
          commission: '',
          isLoading: true,
        })
        let refinedURL
        let productURL = new URL(productUrl)
        if (
          productURL.host === 'www.containerstore.com' &&
          productURL.search.includes('q=')
        ) {
          productURL.search.split('&').forEach(param => {
            if (param.includes('productId')) {
              refinedURL = productURL.origin + productURL.pathname + '?' + param
            }
          })
        } else {
          refinedURL = productUrl
        }
        const shortenedValue = await axios.post(
          `${baseURL}/${ENDPOINTS.shortenLink}`,
          { url: refinedURL, productId, productName, productImage }
        )
        const { data } = shortenedValue.data
        if (data && data.shortUrl?.length > 0) {
          setShortenedLink({
            trackingLink: data.trackingLink,
            value: data.shortUrl,
            commission: data.commission,
            isLoading: false,
          })
        }
      } else {
        handleOpenModal()
      }
    } catch (err) {
      if (err?.response?.data?.message == t('messages.retailerNotConnected')) {
        setError(err.response.data.message)
      } else {
        setError(t('messages.somethingWentWrong'))
      }
      setShortenedLink({
        trackingLink: '',
        value: '',
        commission: '',
        isLoading: false,
      })
    }
  }

  const copyToClipboard = (event: any) => {
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
      setCopyMessage(t('messages.copiedToClipboard'))
    } else {
      setCopyMessage(t('messages.couldNotSelectText'))
    }
  }

  const handleConvertAnotherLink = () => {
    setShortenedLink({
      trackingLink: '',
      value: '',
      commission: '',
      isLoading: false,
    })
    setCopyMessage('')
  }

  return (
    <>
      {copyMessage && (
        <Box mt={2.5}>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.currentMessageStyle}
          >
            {copyMessage}
          </Typography>
        </Box>
      )}
      {shortenedLink.value ? (
        <>
          <Box mt={2.5}>
            <div
              className={`${classes.inputContainer} ${classes.convertedLink}`}
            >
              <CHRInput
                placeholder=""
                value={shortenedLink.value}
                onChange={() => shortenedLink.value}
                elementRef={textAreaRef}
              />
              {document.queryCommandSupported('copy') && (
                <CHRButton
                  label={t('createLink.copyLink')}
                  onClick={copyToClipboard}
                />
              )}
            </div>
          </Box>
          <Box mt={1.5} mb={2.5}>
            <div className={classes.inputContainer}>
              <button
                label={t('createLink.convertAnotherLink')}
                onClick={handleConvertAnotherLink}
                className={classes.convertAnotherLinkBtn}
              >
                {t('createLink.convertAnotherLink')}
              </button>
            </div>
          </Box>
        </>
      ) : (
        <>
          {error && <p className={classes.errorMsg}>{error}</p>}
          <button
            className={`${classes.modalButton} ${classes.whiteBgButton} ${classes.createShareLink}`}
            onClick={handleCreateLink}
            disabled={shortenedLink.isLoading}
          >
            {shortenedLink.isLoading ? (
              <span className={classes.spinner}>
                <CircularProgress color="primary" />
              </span>
            ) : (
              <Typography variant="button">
                {t('createLink.createShareLink')}
              </Typography>
            )}
          </button>
        </>
      )}
    </>
  )
}

export default withTranslation()(CreateProductShareLink)
