import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  seoHeading: {
    fontSize: '16px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.75 !important',
    marginBottom: '30px !important',
    fontWeight: 400,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginBottom: '15px !important',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    height: '100%',
    width: '100%',
    maxWidth: 1190,
    margin: 'auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    [`& nav.MuiBreadcrumbs-root`]: {
      marginBottom: 15,
      [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
        marginBottom: 25,
      },
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginBottom: 30,
      },
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
  productImage: {
    width: '100%',
    maxWidth: 561,
    height: 798,
    objectFit: 'contain',
    margin: 'auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: 376,
      height: 440,
    },
  },
  productInfo: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingTop: 40,
    },
    width: '100%',
    maxWidth: '400px !important',
    margin: 'auto !important',
  },
  productInfoTitle: {
    marginBottom: 10,
  },
  productInfoPrice: { display: 'flex', justifyContent: 'flex-end' },
  productInfoDescription: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    padding: 20,
    textDecoration: 'none',
  },
  blackBgButton: {
    color: COLORS.white,
    backgroundColor: COLORS.black,
    marginBottom: 40,
  },
  whiteBgButton: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    border: `2px solid ${COLORS.black}`,
    marginBottom: 10,
  },
  addToboardText: {
    margin: 'auto !important',
  },
  addToboardContainer: {
    display: 'flex',
  },
  socialTitle: {
    textAlign: 'center',
    marginTop: 20,
  },
  socialButton: {
    width: 50,
    height: 50,
    border: `2px solid ${COLORS.black}`,
    borderRadius: '50%',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    margin: '0 6px',
    textAlign: 'center',
    padding: 0,
    fontSize: 25,
    lineHeight: '50px',
  },
  socialIcon: {
    width: '100%',
    maxWidth: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 40,
    top: 40,
    cursor: 'pointer',
  },
  brandLink: {
    color: COLORS.black,
    '&:active': {
      color: COLORS.black,
    },
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addToShoppingBoard: {
    cursor: 'pointer',
    '&:disabled': {
      border: `1px solid #999999`,
      backgroundColor: `#cccccc`,
      color: `#666666`,
    },
  },
}))
